













































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import { BTable, BvTableCtxObject } from 'bootstrap-vue';
import { eventOff, eventOn } from 'vue-context/src/js/utils';
import { columns } from './components/tabela';
import SolicitacaoBanco from '@/plugins/api/SolicitacaoBanco';
import ConfirmarDados from './components/ConfirmarDados.vue';
import DadosBancariosContext from './components/ContextMenu.vue';

@Component({
  components: {
    ConfirmarDados,
    DadosBancariosContext,
  },
})
export default class DadosBancariosIndex extends Vue {
  @Ref() readonly table!: BTable;
  @Ref() readonly context!: DadosBancariosContext;

  public columns = columns;
  public loading = false;
  public currentPage = 1;
  public totalRows = 1;
  public perPage = 50;

  public galleryOptions = {};
  public galleryImages: string[] = [];
  public galleryIndex: number | null = null;

  public mounted(): void {
    eventOn(this.table.$el, 'scroll', this.context.menu.close);
  }

  destroy(): void {
    eventOff(this.table.$el, 'scroll', this.context.menu.close);
  }

  provider(
    context: BvTableCtxObject,
    callback: (data: IDadosBancarios[]) => void
  ): void {
    this.loading = true;
    this.galleryImages = [];
    const request = {
      params: {
        page: context.currentPage,
        size: context.perPage,
        sortBy: context.sortBy,
        sortDesc: context.sortDesc ? 'DESC' : 'ASC',
      },
    };

    SolicitacaoBanco.get(request)
      .then((response: GetSolicitacoesResponse) => {
        const solicitacoes = response?.data ?? [];
        solicitacoes.forEach((solicitacao) => {
          if (solicitacao.foto_cartao !== '') {
            this.galleryImages.push(
              `//dashboard.parceirosproteauto.com.br${solicitacao.foto_cartao}`
            );
          }
        });
        callback(solicitacoes);
        this.totalRows = response?.metadata.total ?? 0;
      })
      .catch(() => {
        callback([]);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public refresh(): void {
    this.table.refresh();
  }

  public clearSelection(): void {
    this.table.clearSelected();
  }

  public contextMenu(
    item: IDadosBancarios,
    data: number,
    event: MouseEvent
  ): void {
    event.preventDefault();
    this.table.selectRow(data);
    this.context.open(event, item);
  }

  public openGallery(data: string): void {
    let index: number | null = this.galleryImages.findIndex((image) => {
      return image === `//dashboard.parceirosproteauto.com.br${data}`;
    });
    if (index === -1) {
      index = null;
    }
    this.galleryIndex = index;
  }
}
