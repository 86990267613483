




























































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { SolicitacaoBanco } from '@/plugins/api';

@Component
export default class ConfirmarDados extends Vue {
  @Prop() public readonly dados!: IDadosBancarios;
  public processing = false;
  public editMode = false;
  public denyMode = false;

  public contaTipos = [
    { id: 'cc', label: 'Corrente' },
    { id: 'cp', label: 'Poupança' },
  ];

  get bancos(): IBanco[] {
    return this.$store.state.bancos ?? [];
  }

  get editPopoverId(): string {
    return `confirmar-dados-${this.dados.id}`;
  }

  get denyPopoverId(): string {
    return `rejeitar-dados-${this.dados.id}`;
  }

  confirmar(situacao: string): void {
    this.processing = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let dados = ({ ...this.dados } as unknown) as any;
    if (dados.bancos) {
      dados.banco = dados.bancos.id;
    }
    dados.situacao = situacao;
    delete dados.id;
    delete dados.usuarios;
    delete dados.bancos;
    dados = dados as IDadosBancarios;

    SolicitacaoBanco.put(this.dados.id, dados)
      .then((result) => {
        this.processing = false;
        this.$emit('change', result.success);
      })
      .catch(() => {
        this.processing = false;
        this.$emit('change', false);
      })
      .finally(() => {
        this.processing = false;
      });
  }

  editar(show: boolean): void {
    this.editMode = show;
    this.denyMode = false;
  }

  rejeitar(show: boolean): void {
    this.editMode = show;
    this.editMode = false;
  }

  dismiss(): void {
    this.$root.$emit('bv::hide::popover');
  }
}
