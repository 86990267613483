import i18n from '@/i18n';
import { BvTableField } from 'bootstrap-vue';

const columns: Array<{ key: string } & BvTableField> = [
  {
    key: 'id',
    label: i18n.t('colunas.id') as string,
    sortable: true,
  },
  {
    key: 'usuarios.id',
    label: i18n.tc('colunas.nome.indicador', 2) as string,
    sortable: true,
  },
  {
    key: 'titular',
    label: i18n.t('indicador.banco.titular') as string,
    sortable: true,
  },
  {
    key: 'cpf',
    label: i18n.t('indicador.banco.cpf') as string,
    sortable: true,
  },
  {
    key: 'usuarios.cpf_cnpj',
    label: i18n.t('indicador.colunas.cpf') as string,
    sortable: true,
  },
  {
    key: 'banco',
    label: i18n.t('indicador.banco.nome') as string,
    thStyle: { width: '80px' },
    thClass: 'text-center',
    sortable: true,
  },
  {
    key: 'agencia',
    label: i18n.t('indicador.banco.agencia') as string,
    thStyle: { width: '100px' },
    thClass: 'text-center',
    tdClass: 'text-right',
    sortable: true,
  },
  {
    key: 'tipo',
    label: i18n.t('indicador.banco.tipo') as string,
    thStyle: { width: '100px' },
    thClass: 'text-center',
    sortable: true,
  },
  {
    key: 'conta',
    label: i18n.t('indicador.banco.conta') as string,
    thStyle: { width: '100px' },
    thClass: 'text-center',
    tdClass: 'text-right',
    sortable: true,
  },
  {
    key: 'foto_cartao',
    label: i18n.t('indicador.banco.foto') as string,
    thStyle: { width: '60px' },
    thClass: 'text-center',
    sortable: true,
  },
  {
    key: 'situacao',
    label: i18n.t('colunas.situacao') as string,
    thStyle: { width: '80px' },
    thClass: 'text-center',
    sortable: true,
  },
  {
    key: 'acoes',
    label: i18n.t('comum.acoes') as string,
    thStyle: { width: '100px' },
    thClass: 'text-center',
    sortable: false,
  },
];

export { columns };
